import React from "react";

// MATERIAL UI
import { Typography, Box } from "@mui/material";

const CallToAction = ({ title, description, cta }) => {
  return (
    <Box sx={{ mt: 12, mb: 20, textAlign: "center" }}>
      <Typography variant="h2">{title}</Typography>
      <Typography sx={{ maxWidth: 800, margin: "auto", mb: 3 }}>
        {description}
      </Typography>
      {cta && cta}
    </Box>
  );
};

export default CallToAction;
