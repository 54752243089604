import React from "react";
import { graphql } from "gatsby";

// MATERIAL UI
import { styled } from "@mui/system";
import { Button } from "@mui/material";

// COMPONENTS
import Layout from "components/Layout";
import CallToAction from "components/CallToAction";

const BlogPost = ({ data }) => {
  const post = data.markdownRemark;

  console.log("-> post: ", post);
  console.log("-> data: ", data);

  return (
    <Layout
      pageTitle={post.frontmatter.title}
      metaTitle={post.frontmatter.title}
    >
      <>
        <PostImage src={post.frontmatter.image} alt={post.frontmatter.title} />
        <Container dangerouslySetInnerHTML={{ __html: post.html }} />
        <CallToAction
          title="Never get lost between lots of links again!"
          description="Ready to save links faster, stay organized and focus more on what matters? Add Pau to your chrome extension now to increase your productivity."
          cta={
            <Button
              variant="contained"
              href="https://chrome.google.com/webstore/detail/pau/oiblkeiiodficdoblmjihonngeejcmbo"
              target="_blank"
              rel="noreferrer"
              data-splitbee-event="Redirect: Chrome Store"
            >
              Add Pau To My Browser 🚀
            </Button>
          }
        />
      </>
    </Layout>
  );
};

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image
        date
      }
    }
  }
`;

const Container = styled("div")(({ theme }) => ({
  maxWidth: 800,
  margin: "auto",
  marginBottom: 200,

  h1: theme.typography.h1,
  h2: theme.typography.h2,
  h3: theme.typography.h3,
  p: theme.typography.body1,
  img: {
    borderRadius: 8,
    width: "100%",
  },
}));

const PostImage = styled("img")({
  display: "block",
  width: "100%",
  maxWidth: 800,
  margin: "auto",
  marginBottom: 40,
  borderRadius: 16,
});

export default BlogPost;
